<script setup lang="ts">
interface Props {
  redirect?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  redirect: true,
});

const user = useSupabaseUser();
const supabase = useSupabaseClient();

const loading = ref(false);
const email = ref("");
const password = ref("");
const errorMessage = ref("");

const handleLogin = async () => {
  try {
    errorMessage.value = "";
    loading.value = true;
    const { error } = await supabase.auth.signInWithPassword({
      email: email.value,
      password: password.value,
    });
    if (error) throw error;
  } catch (error) {
    errorMessage.value = (error as Error).message;
  } finally {
    loading.value = false;
  }
};

watchEffect(() => {
  if (user.value && props.redirect) {
    navigateTo("/");
  }
});
</script>
<template>
  <main>
    <section
      class="container mx-auto w-full flex flex-wrap items-center justify-center px-5 text-gray-600"
    >
      <form
        class="bg-opacity-50 mt-10 flex w-full flex-col rounded-lg border-gray-700 p-8 md:mt-0"
        @submit.prevent="handleLogin"
      >
        <h2 class="mb-5 text-2xl font-bold text-blue-500">Login</h2>
        <UAlert
          v-show="errorMessage.length > 0"
          color="red"
          class="mb-2"
          variant="outline"
          :title="errorMessage"
        />
        <div class="relative mb-4">
          <label for="full-name" class="text-sm leading-7 text-gray-600"
            >Email</label
          >
          <input
            id="email"
            v-model="email"
            type="email"
            name="email"
            class="bg-opacity-20 w-full rounded border border-gray-600 bg-transparent py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-[#42b883] focus:bg-transparent focus:ring-2 focus:ring-transparent"
            required
          />
        </div>
        <div class="relative mb-4">
          <label for="password" class="text-sm leading-7 text-gray-600"
            >Password</label
          >
          <input
            id="password"
            v-model="password"
            name="password"
            type="password"
            class="bg-opacity-20 w-full rounded border border-gray-600 bg-transparent py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-[#42b883] focus:bg-transparent focus:ring-2 focus:ring-transparent"
            required
          />
        </div>
        <input
          type="submit"
          class="button block cursor-pointer rounded border-0 bg-blue-500 py-2 px-8 font-sans font-bold text-white transition-colors duration-500 hover:bg-blue-500 focus:outline-none"
          :value="loading ? 'Loading' : 'Submit'"
          :disabled="loading"
        />
      </form>
    </section>
  </main>
</template>
